@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_hero_small.scss";

.block_hero_small {
    background-color: $color-primary;

    &:after {
        content: '';
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4LjUgMC40ODQzNzVWMjguNDg0NEgwLjVMMjguNSAwLjQ4NDM3NVoiIGZpbGw9IiNEMkY1RTEiLz4KPHBhdGggZD0iTTI4LjUgMjguNDg0NEg1Ni41VjU2LjQ4NDRIMjguNVYyOC40ODQ0WiIgZmlsbD0iIzAwRDg4MCIvPgo8L3N2Zz4K");
        position: absolute;
        bottom: 0;
        right: 0;
        width: 56px;
        height: 56px;

        @include breakpoint(xlarge) {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9Ijk5IiB2aWV3Qm94PSIwIDAgMTAwIDk5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNTAgMFY1MEgwTDUwIDBaIiBmaWxsPSIjRDJGNUUxIi8+CjxwYXRoIGQ9Ik01MC4wMDAxIDUwSDEwMFYxMDBINTAuMDAwMVY1MFoiIGZpbGw9IiMwMEQ4ODAiLz4KPC9zdmc+Cg==");
            width: 100px;
            height: 99px;
        }
    }

    .heading-h1, p {
        color: $color-white;
    }
}
